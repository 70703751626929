import { useEffect } from "react";
import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonTypes,
} from "icr-shared-components";
import { ButtonStyles } from "icr-shared-components/dist";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import Card, { Type } from "../../components/Card";
import "../Versicherungen/Versicherungen.scss";
import { ReactComponent as HomeIcon } from "../../assets/icons/Desktop/house-icon.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { Top } from "../../components/Card/Content/Top";
import Chart from "../../components/Chart";
import {
  getWohneigentumData,
  WohneigentumData,
} from "../../actions/wohneigentum";
import { RootState } from "../../reducers";
import { Action, setLoading } from "../../actions";
import { formatMoney } from "../../utils/currency";
import { navigateToSimulator } from "../../utils";
import { FinanceBox } from "../../components/sidebar";
import { useTranslation } from "react-i18next";
import { cmsSchemas } from "../../constants";
import CustomRichText from "../../components/RichText";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import { useGetCMSContent, useGetSidebarCMSContent } from "../../hooks/cms";
import { HygraphSidebarComponents } from "../../components/sidebar/HygraphSidebarComponents";

interface Props extends RouteComponentProps {
  getWohneigentumData: any;
  setLoading: any;
  wohneigentumData: WohneigentumData;
}

const Wohneigentum: React.FC<Props> = (props) => {
  const schema = cmsSchemas.homeOwnershipFinancing.plural;
  const { content: cmsContent } = useGetCMSContent({ schema });
  const { content: cmsSidebarContent } = useGetSidebarCMSContent({ schema });
  const sidebarBoxes = cmsSidebarContent?.sidebar;

  const { t: translate } = useTranslation([
    "dashboard",
    "homeOwnershipPage",
    "app",
  ]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "homeOwnershipPage", defaultValue });

  const getInitialData = async () => {
    try {
      setLoading(true);
      await props.getWohneigentumData();
      setLoading(false);
    } catch (error) {
      console.error("error in getting Wohneigentum page initial data", error);
    }
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = props.wohneigentumData;
  let lastxAxisElementHomeownership = 15;

  const reactManagedSidebarComponents = {
    financeExpertBox: <FinanceBox {...{ ...data.financeExpertBox }} />,
    fundingRequestBox: (
      <>
        <div className="p-48">
          <h4 className="mb-16">{data.fundingRequestBox.title}</h4>

          <p className="mb-32">{data.fundingRequestBox.text}</p>

          <Button
            icon={<Arrow />}
            type={ButtonTypes.Standard}
            color={ButtonColors.Action}
            style={ButtonStyles.Secondary}
            size={ButtonSizes.Full}
            onClick={() =>
              window.open(
                data.fundingRequestBox.link,
                "_self",
                "noopener noreferrer"
              )
            }
          >
            {data.fundingRequestBox.linkText}
          </Button>
        </div>
        <hr />
      </>
    ),
    mortageRequestBox: (
      <>
        <div className="p-48">
          <h4 className="mb-12">{data.mortageRequestBox.title}</h4>

          <p className="mb-24">{data.mortageRequestBox.text}</p>

          <Button
            icon={<Arrow />}
            type={ButtonTypes.Standard}
            color={ButtonColors.Action}
            style={ButtonStyles.Secondary}
            size={ButtonSizes.Full}
            onClick={() =>
              window.open(
                data.mortageRequestBox.link,
                "_self",
                "noopener noreferrer"
              )
            }
          >
            {data.mortageRequestBox.linkText}
          </Button>
        </div>
        <hr />
      </>
    ),
  };

  return (
    <div className="wohneigentum-page">
      <div className="page-top">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            props.history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD))
          }
        >
          <Arrow className="rotate-180" />{" "}
          <span className="ml-16">
            {translate("buttons.backToDashboard", {
              ns: "app",
              defaultValue: "Zurück zum dashboard",
            })}
          </span>
        </Button>
      </div>

      <h1 className="mb-16">{t("title", "Wohneigentum finanzieren")}</h1>

      <p className="mb-48 max-w-lg-100 max-w-xl-75 max-w-50">
        {t(
          "text",
          "Erfahren Sie mehr über die verschiedenen Möglichkeiten der Finanzierung von Wohneigentum (WEF)."
        )}
      </p>

      <div className="versicherungen-page-content two-column-layout">
        <div className="left">
          {data.homeOnwershipBox && data.homeOnwershipBox.isVisible && (
            <Card className="mb-48" type={Type.Standard}>
              <Top
                className="mb-58"
                title={formatMoney(data.homeOnwershipBox.maxAmount)}
                icon={<HomeIcon />}
                text={
                  data.homeOnwershipBox.projectedGraphUnavailable
                    ? translate(
                        "homeOwnershipBox.noDataInfo",
                        "Wohneigentumsvorbezug ab {{maxAge}} Jahren ist nicht mehr möglich",
                        { maxAge: data.homeOnwershipBox?.maxAge }
                      )
                    : t(
                        "homeOwnershipBox.subTitle",
                        "erhalten Sie mehr über die verschiedenen Möglichkeiten der Finanzierung von Wohneigentum."
                      )
                }
              />
              {data.homeOnwershipBox.projectedGraphUnavailable ? (
                <div className="without-chart-content">
                  {translate(
                    "homeOwnershipBox.noDataInfo",
                    "Wohneigentumsvorbezug ab {{maxAge}} Jahren ist nicht mehr möglich",
                    { maxAge: data.homeOnwershipBox?.maxAge }
                  )}
                </div>
              ) : (
                <>
                  <Chart
                    historicalGraph={data.homeOnwershipBox.projectedGraph}
                    name="top"
                    xAxisFormat={(d) => {
                      if (d.getFullYear() !== lastxAxisElementHomeownership) {
                        lastxAxisElementHomeownership = d.getFullYear();
                        return `${d.getFullYear()}`;
                      } else {
                        return "";
                      }
                    }}
                    yTicksDivision={1000}
                  />

                  <Button
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                    onClick={() =>
                      navigateToSimulator(data.homeOnwershipBox.link)
                    }
                  >
                    {translate("buttons.simulateNow", {
                      ns: "app",
                      defaultValue: "Jetzt simulieren",
                    })}
                    <Arrow className="ml-16" />
                  </Button>
                </>
              )}
            </Card>
          )}

          {cmsContent?.main && (
            <Card type={Type.Standard}>
              <CustomRichText
                content={cmsContent?.main}
                simulationBox={data.simulationBox}
              />
            </Card>
          )}
        </div>

        <div className="right">
          <Card>
            {sidebarBoxes && (
              <HygraphSidebarComponents
                boxes={sidebarBoxes}
                data={data}
                reactManagedSidebarComponents={reactManagedSidebarComponents}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  wohneigentumData: state.wohneigentum.wohneigentumData,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getWohneigentumData: bindActionCreators(getWohneigentumData, dispatch),
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Wohneigentum));

import { LOG_OUT, SET_WOHNEIGENTUM_DATA } from "../constants/actions";
import { Action } from "../actions";
import { WohneigentumData } from "../actions/wohneigentum";
import { financeExpertBox } from "../constants/mockups";

export interface WohneigentumState {
  wohneigentumData: WohneigentumData;
}

const getInitialState = () => {
  return {
    wohneigentumData: {
      title: "Wohneigentum finazieren",
      text: "Erharen Sie mehr über die verschiedenen Möglichkeiten der Finanzierung von Wohneigentum",
      homeOnwershipBox: {
        projectedGraphUnavailable: false,
        maxAmount: 0,
        maxAge: 0,
        noDataInfo: "",
        subTitle: "maximale Wohneigentumsfinazierung in CHF",
        projectedGraph: [],
        isVisible: false,
        link: "",
        linkText: "JETZT SIMULIEREN",
      },
      actionsBox: {
        title: "Welche Finanzierungsvarianten sind möglich?",
        text: "Nebst dem Vorbezug von Guthaben zur Finanzierung von selbst bewohntem Wohneigentum gibt es auch die Möglichkeit, Pensionskassenbeiträge zu verpfänden. Zudem bitten wir auch direkt vorteilhafte HYpotheken an.",
        isVisible: false,
        actionList: [],
      },
      simulationBox: {
        title: "Simulation",
        toolTip: "Erklärung zur Simulation als Tooltip",
        text: "Simulieren Sie verschiedene Vorsorgeszenarien und fin den Sie heraus, wie siche dies auf Ihre Finazielle Situation auswirken",
        link: "",
        linkText: "SIMULIEREN STARTEN",
        isVisible: false,
      },
      fundingRequestBox: {
        title: "Vorbezug anmelden",
        text: "Melden Sie einen Vorbezug für die Finanzierung von Wohneigentum an.",
        link: "",
        linkText: "VORBEZUG ANMELDEN",
        isVisible: false,
      },
      mortageRequestBox: {
        title: "Offerte für Hypothek bestellen",
        text: "Bestellen Sie uverbindlich eine Offerte für eine Hypothek",
        link: "",
        linkText: "BESTELLEN",
        isVisible: false,
      },
      contactBox: {
        title: "Kontakt",
        text: "Gerne beraten wir Sie persönlich.",
        link: "",
        linkText: "KONTAKT AUFNEHMEN",
        isVisible: false,
      },
      financeExpertBox,
      homeOnwershipPageContentBox: {
        content: "",
      },
    },
  };
};

const app = (state: WohneigentumState = getInitialState(), action: Action) => {
  switch (action.type) {
    case SET_WOHNEIGENTUM_DATA: {
      return { ...state, wohneigentumData: action.payload };
    }

    case LOG_OUT: {
      console.log("log out");
      return { ...getInitialState() };
    }

    default: {
      return { ...state };
    }
  }
};

export default app;

import classNames from "classnames";
import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonStyles,
  ButtonTypes,
} from "icr-shared-components/dist";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Dispatch, bindActionCreators } from "redux";
import { current, globalSettings, logOutLocally } from "../../actions";
import { ReactComponent as LogOut } from "../../assets/icons/log-out.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile-icon.svg";

import { RootState } from "../../reducers";
import Dropdown, { DropdownTypes } from "../Dropdown";

import LanguageSelector from "../LanguageSelector/LanguageSelector";
import styles from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import { DefaultRouteNames, getRouteByLanguage } from "../../routes";
import { i18n } from "../../locale";

interface Props {
  children?: any;
  logOutLocally: any;
  currentUser: any;
  current: any;
  logoUrl: any;
  configurations: string;
}

const Header = ({
  children,
  logOutLocally,
  logoUrl,
  currentUser,
  configurations,
  current,
}: Props) => {
  const history = useHistory();
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [headerTopPosition, setHeaderTopPosition] = useState(0);

  const { t: translate } = useTranslation(["profile"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const loadCurrentUser = () => {
    if (!currentUser) {
      current();
    }
  };

  useEffect(() => {
    loadCurrentUser();
  }, []);

  let previous_scroll_top = null;

  const handleScroll = () => {
    const body_top = document.body.getBoundingClientRect().top;
    if (previous_scroll_top == null) {
      setHeaderTopPosition(0);
    } else if (body_top > previous_scroll_top) {
      // scrolling up
      setIsScrollingDown(false);
      setHeaderTopPosition(0);
    } else {
      // scrolling down
      if (body_top > -80) {
        setIsScrollingDown(true);
        setHeaderTopPosition(body_top);
      } else {
        setHeaderTopPosition(-80);
      }
    }
    previous_scroll_top = body_top;
  };

  return (
    <div
      className={classNames(styles.header, {
        [styles.isScrollingDown]: isScrollingDown,
      })}
      style={{ top: headerTopPosition }}
    >
      {logoUrl == null && (
        <h4 className={styles.headerTitle}>Pensionskasse BVG</h4>
      )}

      {logoUrl && (
        <img className={styles.headerLogo} src={logoUrl} alt="Header logo" />
      )}

      {children}

      <div className="d-flex">
        <div className="mr-24">
          <LanguageSelector />
        </div>

        <Dropdown
          icon={<ProfileIcon />}
          title={
            (currentUser?.firstname || "") + " " + (currentUser?.lastname || "")
          }
          type={DropdownTypes.Account}
          classNameContent={"user-dropdown-content"}
        >
          {(handleClose) => (
            <div className="p-32">
              {!!currentUser && (
                <h5>
                  {currentUser?.firstname} {currentUser?.lastname}
                </h5>
              )}
              {!!currentUser && (
                <p className="mb-40">{currentUser?.emailAddress}</p>
              )}
              <p
                className="mb-24 cursor-pointer"
                onClick={() => {
                  history.push(getRouteByLanguage(DefaultRouteNames.PROFILE));
                  handleClose();
                }}
              >
                {t("page.title", "Profil")}
              </p>
              <hr className="mb-24" />
              <p
                className="mb-24 cursor-pointer"
                onClick={() => {
                  window.open(
                    configurations[i18n.language]?.conditionsOfUse,
                    "_blank"
                  );
                }}
              >
                {t("termsOfUse.text", "Nutzungsbedingungen")}
              </p>
              <hr className="mb-40" />
              <Button
                style={ButtonStyles.Secondary}
                size={ButtonSizes.Full}
                color={ButtonColors.Error}
                type={ButtonTypes.Standard}
                onClick={() => logOutLocally()}
              >
                {t("button.exit", "Abmelden")}
                <LogOut className="ml-16" />
              </Button>
            </div>
          )}
        </Dropdown>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUser: state.app.currentUser,
  logoUrl: state.app.logoUrl,
  configurations: state.app.configurations,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  current: bindActionCreators(current, dispatch),
  logOutLocally: bindActionCreators(logOutLocally, dispatch),
  globalSettings: bindActionCreators(globalSettings, dispatch),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Header);
